var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-edit-component" },
    [
      _c("div", { staticClass: "left-part" }, [
        _c(
          "div",
          { staticClass: "tabs-line" },
          _vm._l(_vm.tabList, function (item, index) {
            return _c(
              "span",
              {
                key: index,
                class: ["single-tab", item.active && "single-tab-active"],
                on: {
                  click: function ($event) {
                    return _vm.tabChangeHandler(item)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "filter-line" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入", "suffix-icon": "el-icon-search" },
              on: { input: _vm.searchHandler },
              model: {
                value: _vm.searchKey,
                callback: function ($$v) {
                  _vm.searchKey = $$v
                },
                expression: "searchKey",
              },
            }),
          ],
          1
        ),
        _vm.activeTab === "CustomList"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.customLoading,
                    expression: "customLoading",
                  },
                ],
                staticClass: "list-container",
                on: { scroll: _vm.containerScroll },
              },
              [
                _vm._l(_vm.customList, function (item) {
                  return [
                    item.cardList.filter((item) => item.show).length > 0
                      ? _c(
                          "div",
                          {
                            key: `title-${item.groupId}`,
                            staticClass: "label-line",
                            attrs: { id: item.groupId },
                            on: {
                              click: function ($event) {
                                return _vm.labelShowToggle(item)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    `${item.groupName}(${
                                      item.cardList.length
                                    }/${_vm.customList.reduce(
                                      (prev, current) =>
                                        prev + current.cardList.length,
                                      0
                                    )})`
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _c("span", [
                              item.show
                                ? _c("i", {
                                    staticClass: "el-icon-caret-bottom",
                                  })
                                : _c("i", { staticClass: "el-icon-caret-top" }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.show,
                            expression: "item.show",
                          },
                        ],
                        key: `groud-${item.groupId}`,
                        staticClass: "list-main-container",
                      },
                      [
                        _c(
                          "VueDraggable",
                          {
                            staticClass: "container",
                            attrs: {
                              animation: "150",
                              ghostClass: "ghost",
                              sort: false,
                              group: {
                                name: "person",
                                pull: "clone",
                                put: false,
                              },
                              filter: ".ignore",
                              clone: _vm.cloneHandler,
                            },
                            model: {
                              value: item.cardList,
                              callback: function ($$v) {
                                _vm.$set(item, "cardList", $$v)
                              },
                              expression: "item.cardList",
                            },
                          },
                          _vm._l(item.cardList, function (single) {
                            return _c(
                              "div",
                              {
                                key: single.cardId,
                                staticClass: "item",
                                style: {
                                  width: _vm.cardWidthReander(single),
                                },
                              },
                              [
                                single.show
                                  ? [
                                      _c("CustomCard", {
                                        attrs: { dataset: single },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            )
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.templateLoading,
                    expression: "templateLoading",
                  },
                ],
                staticClass: "list-container",
              },
              _vm._l(_vm.templateList, function (item, index) {
                return _c("div", { key: index, staticClass: "template-card" }, [
                  _c("div", { staticClass: "template-name" }, [
                    _vm._v(
                      "\n          " + _vm._s(item.templateName) + "\n        "
                    ),
                  ]),
                  _c("img", {
                    staticClass: "template-img",
                    attrs: {
                      draggable: "false",
                      src: _vm.coverImageRender(item),
                      alt: "",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "buttons-container" },
                    [
                      _c(
                        "r-button",
                        {
                          staticClass: "single-button",
                          staticStyle: { width: "100%" },
                          on: {
                            click: function ($event) {
                              return _vm.templateViewHandler(item)
                            },
                          },
                        },
                        [_vm._v("预览")]
                      ),
                      _c(
                        "r-button",
                        {
                          staticClass: "single-button",
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.templateApplyHandler(item)
                            },
                          },
                        },
                        [_vm._v("应用")]
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
      ]),
      _c("div", { staticClass: "right-part" }, [
        _c("div", { staticClass: "right-header-line" }, [
          _c("div", { staticClass: "title-label" }, [_vm._v("首页")]),
          _c(
            "div",
            { staticClass: "buttons-container" },
            [
              _vm.showLocalButton
                ? _c("el-button", { on: { click: _vm.updateCard } }, [
                    _vm._v("update"),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", plain: "" },
                  on: { click: _vm.clearHandler },
                },
                [_vm._v("一键清空")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.goBackHandler },
                },
                [_vm._v("退出编辑")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveHandler } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading",
              },
            ],
            staticClass: "drag-container",
          },
          [
            _vm.list.length === 0
              ? _c("div", { staticClass: "no-card" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/customer_add_icon.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("\n        选择组件长按鼠标左键拖动到此区域\n      "),
                ])
              : _vm._e(),
            _c(
              "VueDraggable",
              {
                staticClass: "container",
                attrs: {
                  animation: "150",
                  ghostClass: "ghost",
                  group: "person",
                  filter: ".ignore",
                },
                model: {
                  value: _vm.list,
                  callback: function ($$v) {
                    _vm.list = $$v
                  },
                  expression: "list",
                },
              },
              _vm._l(_vm.list, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    style: {
                      width: _vm.cardWidthReander(item),
                    },
                  },
                  [
                    _c("SingleCard", {
                      attrs: { dataset: item, type: "edit" },
                      on: { delete: _vm.deleteHandler },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _c("TemplateView", {
        attrs: { dataset: _vm.templateView },
        on: { close: _vm.templateViewClose, apply: _vm.templateApply },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.confirmDialogShow,
            "before-close": _vm.confirmDialogClose,
            width: "430px",
            "show-close": false,
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "custom-class": "customer-edit-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.confirmDialogShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "header-line" }, [
            _c("div", [_vm._v("警告")]),
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.confirmDialogClose },
            }),
          ]),
          _c("div", { staticClass: "detail-line" }, [
            _c("div", {
              staticClass: "el-message-box__status el-icon-warning",
            }),
            _c("div", { staticClass: "detail-tips" }, [
              _vm._v("是否保存当前编辑？"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.confirmDialogClose },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmExit(false)
                    },
                  },
                },
                [_vm._v("退出")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmExit(true)
                    },
                  },
                },
                [_vm._v("保存并退出")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.needFAQ["TemplateList"]
        ? _c("TemplateFAQ", {
            on: {
              close: function ($event) {
                return _vm.FAQClose("TemplateList")
              },
            },
          })
        : _vm._e(),
      _vm.needFAQ["CustomList"]
        ? _c("CustomerFAQ", {
            on: {
              close: function ($event) {
                return _vm.FAQClose("CustomList")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
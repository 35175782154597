<template>
  <div class="dashboard-edit-left">
    <div class="tabs-line">
      <span
        :class="['single-tab', item.active && 'single-tab-active']"
        v-for="(item, index) in tabList"
        :key="index"
        @click="tabChangeHandler(item)"
      >
        {{ item.label }}
      </span>
    </div>
    <div class="filter-line">
      <el-input
        placeholder="请输入"
        suffix-icon="el-icon-search"
        v-model="searchKey"
      >
      </el-input>
    </div>
    <div class="left-list">
      <components :is="activeTab" />
    </div>
  </div>
</template>

<script>
import TemplateList from "./templateList.vue";
import CustomList from "./customList.vue";

export default {
  name: "dashboard-edit-left",
  components: {
    TemplateList,
    CustomList,
  },
  data() {
    return {
      tabList: [
        {
          label: "数据模板",
          active: false,
          key: "TemplateList",
        },
        {
          label: "自定义",
          active: true,
          key: "CustomList",
        },
      ],
      searchKey: null,
    };
  },
  computed: {
    activeTab() {
      return this.tabList.filter((item) => item.active)[0].key;
    },
  },
  methods: {
    tabChangeHandler(changeItem) {
      this.tabList.forEach((item) => {
        item.active = false;
      });
      changeItem.active = true;

      // 单独卡片：
      const singleCard = {
        // 唯一ID
        id: "xxxxx1",
        // 尺寸大小：100px | 30%
        size: {
          height: "100px",
          width: "33.3%",
          model: "2", // 4 | 8 | 16 | 24 | null
        },
        // 位置：
        position: {
          top: "100px",
          left: "100px",
        },
        // 权限类型：role | point
        permissionType: "role",
        // 权限列表：roleList | pointList
        permissionList: [1, 2, 3],
        // 卡片代码
        code: `<template><div class="re...<\/style>`,
      };

      // 总体布局 | 模板
      const templateList = {
        topSlot: {}, //顶部扩展
        cardList: ["xxx1", "xxx2", "xxx3"],
        // {
        //   sequence: '1-1',
        //   id: 'xxxxx1',
        // },
        // {
        //   sequence: '1-2',
        //   id: '',
        // },
        // {
        //   sequence: '1-3',
        //   id: '',
        // },
        // {
        //   sequence: '2-1',
        //   id: 'xxxxx4',
        // },
        // {
        //   sequence: '2-2',
        //   id: 'xxxxx5',
        // },
        // {
        //   sequence: '2-3',
        //   id: 'xxxxx6',
        // },
      };
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-edit-left {
  height: 100%;
  width: 250px;
  background: #f9fbff;
  .tabs-line {
    height: 60px;
    margin: 0 10px 10px 10px;
    border-bottom: 1px solid #f1f4f6;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 14px;
    line-height: 24px;
    .single-tab {
      color: #5d687c;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      cursor: pointer;
    }
    .single-tab-active {
      color: #252d3d;
      &::after {
        content: "";
        display: inline-block;
        width: 28px;
        height: 2px;
        border-radius: 7px;
        background: #2a61ff;
        position: absolute;
        bottom: -10px;
        left: calc(50% - 14px);
      }
    }
  }
  .filter-line {
    padding: 0 10px;
  }
  .left-list {
    padding: 0 10px 20px 10px;
    margin-top: 20px;
    height: calc(100% - 125px);
    overflow: auto;
  }
}
</style>
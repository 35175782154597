<template>
  <div class="dashboard-edit-component">
    <div class="left-part">
      <div class="tabs-line">
        <span
          :class="['single-tab', item.active && 'single-tab-active']"
          v-for="(item, index) in tabList"
          :key="index"
          @click="tabChangeHandler(item)"
        >
          {{ item.label }}
        </span>
      </div>
      <div class="filter-line">
        <el-input
          placeholder="请输入"
          suffix-icon="el-icon-search"
          @input="searchHandler"
          v-model="searchKey"
        >
        </el-input>
      </div>
      <div
        class="list-container"
        v-loading="customLoading"
        v-if="activeTab === 'CustomList'"
        v-on:scroll="containerScroll"
      >
        <template v-for="item in customList">
          <div
            class="label-line"
            v-if="item.cardList.filter((item) => item.show).length > 0"
            @click="labelShowToggle(item)"
            :id="item.groupId"
            :key="`title-${item.groupId}`"
          >
            <span>
              {{
                `${item.groupName}(${item.cardList.length}/${customList.reduce(
                  (prev, current) => prev + current.cardList.length,
                  0
                )})`
              }}
            </span>
            <span>
              <i class="el-icon-caret-bottom" v-if="item.show"></i>
              <i class="el-icon-caret-top" v-else></i>
            </span>
          </div>
          <div
            class="list-main-container"
            :key="`groud-${item.groupId}`"
            v-show="item.show"
          >
            <VueDraggable
              v-model="item.cardList"
              animation="150"
              ghostClass="ghost"
              class="container"
              :sort="false"
              :group="{ name: 'person', pull: 'clone', put: false }"
              filter=".ignore"
              :clone="cloneHandler"
            >
              <div
                v-for="single in item.cardList"
                :key="single.cardId"
                class="item"
                :style="{
                  width: cardWidthReander(single),
                }"
              >
                <template v-if="single.show">
                  <CustomCard :dataset="single" />
                </template>
              </div>
            </VueDraggable>
          </div>
        </template>
      </div>
      <div class="list-container" v-else v-loading="templateLoading">
        <div
          class="template-card"
          v-for="(item, index) in templateList"
          :key="index"
        >
          <div class="template-name">
            {{ item.templateName }}
          </div>
          <img
            draggable="false"
            :src="coverImageRender(item)"
            class="template-img"
            alt=""
          />
          <div class="buttons-container">
            <!-- <div
              class="single-button view-button"
              @click="templateViewHandler(item)"
            >
              预览
            </div> -->
            <r-button
              style="width: 100%"
              class="single-button"
              @click="templateViewHandler(item)"
              >预览</r-button
            >
            <r-button
              class="single-button"
              plain
              @click="templateApplyHandler(item)"
              >应用</r-button
            >
            <!-- <div
              class="single-button apply-button"
              @click="templateApplyHandler(item)"
            >
              应用
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="right-part">
      <div class="right-header-line">
        <div class="title-label">首页</div>
        <div class="buttons-container">
          <el-button v-if="showLocalButton" @click="updateCard"
            >update</el-button
          >
          <el-button type="danger" plain @click="clearHandler"
            >一键清空</el-button
          >
          <el-button type="primary" plain @click="goBackHandler"
            >退出编辑</el-button
          >
          <el-button type="primary" @click="saveHandler">保存</el-button>
        </div>
      </div>
      <div class="drag-container" v-loading="detailLoading">
        <div class="no-card" v-if="list.length === 0">
          <img src="@/assets/images/customer_add_icon.png" alt="" />
          选择组件长按鼠标左键拖动到此区域
        </div>
        <VueDraggable
          v-model="list"
          animation="150"
          ghostClass="ghost"
          class="container"
          group="person"
          filter=".ignore"
        >
          <div
            v-for="item in list"
            :key="item.id"
            class="item"
            :style="{
              width: cardWidthReander(item),
            }"
          >
            <SingleCard @delete="deleteHandler" :dataset="item" type="edit" />
          </div>
        </VueDraggable>
      </div>
    </div>
    <!-- 模板预览 -->
    <TemplateView
      :dataset="templateView"
      @close="templateViewClose"
      @apply="templateApply"
    />
    <!-- 退出编辑弹框 -->
    <el-dialog
      :visible.sync="confirmDialogShow"
      :before-close="confirmDialogClose"
      width="430px"
      :show-close="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      custom-class="customer-edit-dialog"
    >
      <div class="header-line">
        <div>警告</div>
        <i class="el-icon-close" @click="confirmDialogClose"></i>
      </div>
      <div class="detail-line">
        <div class="el-message-box__status el-icon-warning"></div>
        <div class="detail-tips">是否保存当前编辑？</div>
      </div>
      <div class="button-line">
        <el-button size="small" @click="confirmDialogClose">取消</el-button>
        <el-button size="small" @click="confirmExit(false)">退出</el-button>
        <el-button size="small" type="primary" @click="confirmExit(true)"
          >保存并退出</el-button
        >
      </div>
    </el-dialog>
    <TemplateFAQ
      v-if="needFAQ['TemplateList']"
      @close="FAQClose('TemplateList')"
    />
    <CustomerFAQ v-if="needFAQ['CustomList']" @close="FAQClose('CustomList')" />
  </div>
</template>

<script>
import LeftPart from "./components/leftPart.vue";
import SingleCard from "./components/singleCard.vue";
import CustomCard from "./components/customCard.vue";
import TemplateView from "./components/templateView.vue";
import { VueDraggable } from "vue-draggable-plus";
import { GenNonDuplicateID } from "@/utils/utils.js";
import { cloneDeep } from "lodash";
import {
  deleteCard,
  getCustomCardList,
  getUserPortal,
  saveUserPortal,
  addCustomCard,
  getCustomTemplateList,
} from "@/api/ruge/bmsNew/dashboard.js";
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import { getCustomTemplateDetail } from "@/api/ruge/bmsNew/dashboard.js";
import FAQ from "@/utils/FAQ.js";
import TemplateFAQ from "@/components/FAQ/template.vue";
import CustomerFAQ from "@/components/FAQ/customer.vue";
import { envInfo } from "@/constants/envInfo";
const templateExampleImg = require("@/assets/images/custom_template_example.png");
export default {
  name: "dashboard-edit-component",
  components: {
    LeftPart,
    VueDraggable,
    SingleCard,
    CustomCard,
    WaterEnergyTitle,
    TemplateView,
    CustomerFAQ,
    TemplateFAQ,
  },
  data() {
    return {
      needFAQ: {
        TemplateList: null,
        CustomList: null,
      },
      showLocalButton: window.location.href.includes("localhost"),
      templateExampleImg,
      tabList: [
        {
          label: "数据模板",
          active: true,
          key: "TemplateList",
        },
        {
          label: "自定义",
          active: false,
          key: "CustomList",
        },
      ],
      searchKey: null,
      customLoading: false,
      // 自定义卡片列表
      customList: {},
      detailLoading: false,
      list: [],
      templateLoading: false,
      templateListResource: [],
      templateList: [],
      templateView: {
        show: false,
        templateName: null,
        templateId: null,
      },
      confirmDialogShow: false,
      heightList: [],
    };
  },
  created() {
    this.initListDatas();
    // deleteCard("13feb4bf621d497e84a526668713c7b2");
    // deleteCard("df4ee40392724171babe97285bd21fc2");
  },
  computed: {
    activeTab() {
      const currentActive = this.tabList.filter((item) => item.active)[0].key;
      this.initFAQ(currentActive);
      return currentActive;
    },
  },
  methods: {
    labelShowToggle(item) {
      item.show = !item.show;
      this.$nextTick(() => {
        this.calcHeightList();
        this.containerScroll();
      });
    },
    containerScroll() {
      const containerDom = $(".list-container")[0];
      const offsetTop = containerDom.scrollTop;
      if (offsetTop > 45) {
        for (let i = 0; i < this.heightList.length; i++) {
          if (offsetTop <= this.heightList[i]) {
            this.setActiveByIndex(i);
            break;
          }
        }
      } else {
        $(".label-line").removeClass("fixed-label-line");
      }
    },
    calcHeightList() {
      this.heightList = [];
      // 计算列表每一项的高度
      this.customList.forEach((item, index) => {
        // 45是label-line的高度，懒得计算直接加
        const labelLineHeight = 45;
        const preHeight = this.heightList[index - 1] || 0;
        if (item.show) {
          const listHeight = $(".list-main-container")[index].offsetHeight;
          this.heightList.push(listHeight + labelLineHeight + preHeight);
        } else {
          this.heightList.push(labelLineHeight + preHeight);
        }
      });
      console.log("this.heightList", this.heightList);
    },
    setActiveByIndex(index) {
      const activeId = this.customList[index].groupId;
      $(".label-line").removeClass("fixed-label-line");
      $(`#${activeId}`).addClass("fixed-label-line");
    },
    coverImageRender({ coverPicture }) {
      return coverPicture
        ? `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${coverPicture}`
        : templateExampleImg;
    },
    initFAQ(type) {
      // TemplateList | CustomList
      if (this.needFAQ[type] === false) {
        return;
      }
      this.needFAQ[type] = FAQ.isNeedFAQ(type);
      console.log("this.needFAQ", this.needFAQ[type]);
    },
    FAQClose(type) {
      FAQ.updateFAQ(type, 1);
      this.needFAQ[type] = false;
    },
    updateCard() {
      const params = {
        cardCategory: "运维总览",
        cardId: "da8fdd3925254ed9b28a6f53e0e662de",
        cardName: "设备类型工单统计",
        cardSize: '{"mode": 2}',
        cardType: "line",
        createdBy: null,
        creationDate: null,
        description: null,
        enableStatus: "ENABLE",
        lastUpdateDate: null,
        lastUpdatedBy: null,
        permissionList: "[1,2,3,5]",
        permissionType: "role",
        tenantId: null,
        cardConfig: `<template>
                      <div class="operations-device-orders" v-loading="loading">
                        <div class="itBoxs bgfff absWarp">
                          <div class="ptName">设备类型工单统计</div>
                          <div id="sb_div" ref="chartDom"></div>
                        </div>
                      <\/div>
                    </template>
                    <script>
                      export default {
                        name: "operations-device-orders",
                        data() {
                          return {
                            loading: false,
                            day: 7,
                            projectId: null,
                            myChart: null
                          }
                        },
                        mounted(){
                          this.$eventBus.$on("custom-operations-filter", (datas) => {
                            const { projectId, day } = datas;
                            this.projectId = projectId;
                            this.day = day;
                            this.initCharts()
                          });
                        },
                        methods: {
                          sameTooltip() {
                            return {
                              show: true,
                              trigger: "axis",
                              padding: 0,
                              borderWidth: 1,
                              borderColor: "#F0F7FC",
                              backgroundColor: "#00000000",
                              axisPointer: {
                                type: "line",
                                lineStyle: {
                                  width: 1,
                                  type: "dotted",
                                  opacity: 0.5,
                                  color: this.$customUtils.echartsLinearGradient(0, 1, 0, 0, [
                                    {
                                      offset: 0,
                                      color: "#40DEC2",
                                    },
                                    {
                                      offset: 1,
                                      color: "#40DEC2",
                                    },
                                  ]),
                                },
                              },
                              alwaysShowContent: false,
                              formatter(params) {
                                let domStr = "<div class='tips-main'>";
                                params.forEach((item) => {
                                  const { seriesName, value } = item;
                                  domStr += "<div class='tips-container'><span class='point' style='background:" + item.color + "'></span> " + seriesName + ": " + value + "</div>";
                                });
                                domStr += "</div>";
                                return domStr;
                              },
                            };
                          },
                          // 入口方法
                          async initCharts() {
                            //设备类型工单统计
                            this.loading = true;
                            let params = {
                              isloading: false,
                              day: this.day,
                            };
                            let tbRes = await this.ApiHttp(
                              "/order/tenant/order/deviceReportsList",
                              params
                            ); //查询设备填报趋势
                            let wcRes = await this.ApiHttp(
                              "/order/tenant/order/deviceCompleteList",
                              params
                            ); //查询设备完成趋势
                            if (!tbRes) return;
                            let xArr = [],
                              yArr1 = [],
                              yArr2 = [];
                            for (let i = 0; i < tbRes.length; i++) {
                              let tbItem1 = tbRes[i];
                              xArr.push(tbItem1.classifyName);
                              yArr1.push(tbItem1.number);
                            }
                            for (let it of wcRes) {
                              yArr2.push(it.number);
                            }
                            const chartDom = this.$refs.chartDom;
                            this.myChart = this.$echarts.init(chartDom);
                            let options = {
                              grid: {
                                left: "32px",
                                right: "10px",
                                bottom: "40px",
                                top: "60px",
                              },
                              legend: {
                                right: "0",
                                top: "8px",
                              },
                              tooltip: this.sameTooltip(),
                              xAxis: {
                                type: "category",
                                show: true,
                                axisLabel: {
                                  color: "#8190AC",
                                },
                                axisLine: {
                                  lineStyle: {
                                    color: "#E6E9EE",
                                  },
                                },
                                data: xArr, //['A', 'B','C', 'D', 'E']
                              },
                              yAxis: {
                                type: "value",
                                splitNumber: 3,
                                splitLine: {
                                  show: true,
                                  lineStyle: {
                                    color: "#E6E9EE",
                                  },
                                },
                                axisLine: {
                                  lineStyle: {
                                    color: "#E6E9EE",
                                  },
                                },
                                axisLabel: {
                                  color: "#8190AC",
                                },
                              },
                              series: [
                                {
                                  name: "填报数",
                                  type: "bar",
                                  data: yArr1, //[19, 23, 31, 12, 3, 7],
                                  barWidth: "14px",
                                  itemStyle: {
                                    color: "#2A61FF",
                                    barBorderRadius: [10, 10, 0, 0],
                                  },
                                },
                                {
                                  name: "完成数",
                                  type: "bar",
                                  barWidth: "14px",
                                  itemStyle: {
                                    barBorderRadius: [10, 10, 0, 0],
                                    color: "#4EF2F2",
                                  },
                                  data: yArr2, //[10, 20, 30, 40, 50, 60]
                                },
                              ],
                            };
                            this.myChart.setOption(options, false);
                            this.loading = false;
                          }
                        },
                      };
                    <\/script>
                    <style lang="less">
                      .operations-device-orders {
                        .tips-main {
                          background: #ffffff;
                          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
                          border-radius: 4px 4px 4px 4px;
                          line-height: 30px;
                          padding: 0 15px;
                          color: #000000;
                        }
                        #sb_div {
                          width: 100%;
                          height: 270px;
                        }
                        .absWarp {
                          position: relative;
                          .ptName {
                            position: absolute;
                            top: 0;
                            z-index: 9;
                          }
                        }
                        .bgfff{
                          background: #FFFFFF;;
                        }
                        .itBoxs {
                          border-radius: 10px;
                          height: 270px;
                          padding: 0 20px;
                          .ptName {
                            font-weight: 600;
                            color: #252d3d;
                            font-size: 16px;
                            line-height: 44px;
                          }
                          .orderUl {
                            height: 200px;
                            overflow-y: auto;
                            font-size: 12px;
                            li {
                              padding: 6px 0;
                              color: #77838f;
                            }
                            .rtx {
                              padding-left: 10px;
                              text-align: right;
                            }
                            .no-data-box {
                              width: 280px;
                              margin: 0 auto;
                              color: #8190ac;
                              text-align: center;
                            }
                          }
                        }
                      }
                    <\/style>`,
      };
      addCustomCard(params).then(() => {
        this.initListDatas();
        this.$message.success("更新成功！");
      });
    },
    confirmExit(saveFlag) {
      if (saveFlag) {
        this.saveHandler(() => {
          this.$router.push("/bms/customDashboard");
        });
      } else {
        this.$router.push("/bms/customDashboard");
      }
    },
    confirmDialogClose() {
      this.confirmDialogShow = false;
    },
    goBackHandler() {
      this.confirmDialogShow = true;
    },
    searchHandler() {
      const currentActive = this.tabList.filter((item) => item.active)[0].key;
      const currentKey = this.searchKey.trim().toLowerCase();
      if (currentActive === "TemplateList") {
        this.templateList = this.templateListResource.filter((item) =>
          item.templateName.toLowerCase().includes(currentKey)
        );
      } else {
        this.customList.forEach((single) => {
          single.cardList.forEach((item) => {
            item.show = item.cardName.toLowerCase().includes(currentKey);
          });
        });
      }
    },
    templateApply(list) {
      this.templateViewClose();
      this.list = list.map((item) => {
        item.id = GenNonDuplicateID();
        return item;
      });
    },
    templateViewHandler({ templateId, templateName }) {
      this.templateView.templateId = templateId;
      this.templateView.templateName = templateName;
      this.templateView.show = true;
    },
    templateViewClose() {
      this.templateView.templateId = null;
      this.templateView.templateName = null;
      this.templateView.show = false;
    },
    async templateApplyHandler({ templateId }) {
      if (this.list.length) {
        await this.$confirm(
          `应用后将覆盖当前已选择卡片，确认覆盖吗？`,
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: "再想想",
            type: "warning",
          }
        );
      }
      this.getTemplateById(templateId);
    },
    getTemplateById(templateId) {
      getCustomTemplateDetail({
        templateId,
      }).then((res) => {
        this.list = res.map((item) => {
          item.id = GenNonDuplicateID();
          return item;
        });
      });
    },
    openAllGroup() {
      this.customList.forEach((item) => {
        item.show = true;
      });
    },
    initListDatas() {
      // 自定义卡片列表
      this.customLoading = true;
      getCustomCardList()
        .then((res) => {
          // const result = this.classifyArray(
          //   res.map((item) => {
          //     item.cardSize = JSON.parse(item.cardSize);
          //     item.show = true;
          //     return item;
          //   }),
          //   (item) => item.cardCategory
          // );
          // console.log("result", result);
          res.forEach((item) => {
            item.show = true;
            item.cardList.forEach((single) => {
              single.cardSize = JSON.parse(single.cardSize);
            });
          });
          this.customList = res.filter((item) => item.cardList.length);
          console.log("this.customList", this.customList);
        })
        .finally(() => {
          this.customLoading = false;
        });
      // 获取用户自定义首页
      this.detailLoading = true;
      getUserPortal({
        customType: "home",
      })
        .then((res) => {
          this.list = JSON.parse(res.customConfig).map((item) => {
            item.cardSize = JSON.parse(item.cardSize);
            item.id = GenNonDuplicateID();
            return item;
          });
          console.log("this.list", this.list);
        })
        .finally(() => {
          this.detailLoading = false;
        });
      this.templateLoading = true;
      // 获取模板列表
      getCustomTemplateList({
        templateType: "home",
      })
        .then((res) => {
          this.templateList = res;
          this.templateListResource = res;
        })
        .finally(() => {
          this.templateLoading = false;
        });
    },
    classifyArray(arr, classifierFn) {
      return arr.reduce((classified, item) => {
        const classifier = classifierFn(item);
        (classified[classifier] = classified[classifier] || []).push(item);
        return classified;
      }, {});
    },
    cloneHandler(element) {
      console.log("element", element);
      const cloneObj = cloneDeep(element);
      cloneObj.id = GenNonDuplicateID();
      return cloneObj;
    },
    deleteHandler(id) {
      this.list = this.list.filter((item) => item.id !== id);
    },
    tabChangeHandler(changeItem) {
      this.resetFilterList();
      this.tabList.forEach((item) => {
        item.active = false;
      });
      changeItem.active = true;
      if (changeItem.key === "CustomList") {
        document.getElementsByClassName("list-container")[0].scrollTop = 0;
        this.$nextTick(() => {
          this.calcHeightList();
          this.containerScroll();
        });
      }
    },
    resetFilterList() {
      this.searchKey = null;
      this.templateList = cloneDeep(this.templateListResource);
      this.customList.forEach((ele) => {
        ele.cardList.forEach((item) => {
          item.show = true;
        });
      });
      // for (let key in this.customList) {
      //   this.customList[key].forEach((item) => {
      //     item.show = true;
      //   });
      // }
    },
    async clearHandler() {
      await this.$confirm(
        `确定要清空当前页面卡片？`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: "再想想",
          type: "warning",
        }
      );
      this.list = [];
    },
    saveHandler(cb) {
      saveUserPortal({
        customType: "home",
        // customConfig: JSON.stringify(this.list),
        cardIds: this.list.map((item) => item.cardId),
      })
        .then((res) => {
          this.$message.success("保存成功！");
        })
        .finally(() => {
          setTimeout(() => {
            cb && cb();
          }, 1000);
        });
    },
    cardWidthReander(item) {
      const marginMap = {
        1: 20,
        2: 10,
        3: 0,
      };
      const oneStep = 33.3;
      let finalWidth = 33.3;
      const cardSizeParse =
        typeof item.cardSize === "string"
          ? JSON.parse(item.cardSize)
          : item.cardSize;
      finalWidth = cardSizeParse.mode * oneStep;
      return `calc(${finalWidth}% - ${marginMap[cardSizeParse.mode]}px)`;
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-edit-component {
  display: flex;
  height: 100vh;
  width: 100%;
  .left-part {
    min-width: 250px;
    background: #f9fbff;
    .tabs-line {
      height: 60px;
      margin: 0 10px 10px 10px;
      border-bottom: 1px solid #e3e4e5;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 14px;
      line-height: 24px;
      .single-tab {
        color: #5d687c;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        position: relative;
        cursor: pointer;
      }
      .single-tab-active {
        color: #252d3d;
        &::after {
          content: "";
          display: inline-block;
          width: 28px;
          height: 2px;
          border-radius: 7px;
          background: #2a61ff;
          position: absolute;
          bottom: -10px;
          left: calc(50% - 14px);
        }
      }
    }
    .filter-line {
      padding: 0 10px;
      margin-bottom: 10px;
      ::v-deep .el-input__inner {
        background: #f1f6fd;
      }
    }
    .list-container {
      height: calc(100vh - 146px);
      overflow: auto;
      .label-line {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        height: 25px;
        line-height: 20px;
        font-weight: 500;
        font-size: 14px;
        color: #252d3d;
        border-bottom: 1px solid #e3e4e5;
        margin: 10px;
        position: relative;
        padding-left: 8px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        &::before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 2px;
          height: 12px;
          background: #2a61ff;
          border-radius: 4px;
          left: 0px;
          top: 4px;
        }
      }
      .fixed-label-line {
        height: 30px;
        position: fixed;
        top: 106px;
        left: 0;
        background: #ffffff;
        z-index: 1;
        width: 230px;
      }
      .no-card-container {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        height: 25px;
        line-height: 20px;
        color: #95a2b9;
        border-bottom: 1px solid #e3e4e5;
        margin: 10px;
      }
      .list-main-container {
        display: flex;
        justify-content: center;
      }
      .template-card {
        margin: 20px auto 0;
        width: 230px;
        height: 267px;
        background: #ffffff;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        border: 1px solid #fafafa;
        padding: 10px;
        box-sizing: border-box;
        .template-name {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 12px;
          color: #252d3d;
        }
        .template-img {
          width: 209px;
          height: 148px;
          margin: 10px auto 28px;
        }
        .buttons-container {
          display: flex;
          .single-button {
            cursor: pointer;
            line-height: 36px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            border-radius: 8px;
            flex: 1;
          }
          .view-button {
            border: 1px solid #2a61ff;
            color: #2a61ff;
          }
          .apply-button {
            background: #2a61ff;
            color: #ffffff;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .right-part {
    padding: 0 40px;
    width: 100%;
    .right-header-line {
      height: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .drag-container {
      height: calc(100vh - 72px);
      overflow: auto;
      padding: 20px 10px 0 0;
      position: relative;
      .container {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 30px;
        min-height: calc(100% - 20px);
        margin-bottom: 20px;
        width: 100%;
        border: 1px dotted #ccc;
        .item {
          width: 33.3%;
          border-radius: 5px;
          color: #000;
        }
      }
      .no-card {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        color: #b5bece;
        img {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.customer-edit-dialog {
  margin-top: calc(50vh - 136px) !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .header-line {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 10px;
    font-size: 18px;
    .el-icon-close {
      cursor: pointer;
    }
  }
  .detail-line {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    position: relative;
    .detail-tips {
      padding-left: 40px;
    }
  }
  .button-line {
    text-align: right;
    padding: 15px;
  }
}
</style>
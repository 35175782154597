var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-custom-list" },
    _vm._l(_vm.customList, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("div", { staticClass: "label-line" }, [
            _vm._v("\n      " + _vm._s(item.catelogLabel) + "\n    "),
          ]),
          _c(
            "VueDraggable",
            {
              staticClass: "container",
              attrs: {
                animation: "150",
                ghostClass: "ghost",
                group: { name: "person", pull: "clone", put: false },
                filter: ".ignore",
                clone: _vm.cloneHandler,
              },
              model: {
                value: item.list,
                callback: function ($$v) {
                  _vm.$set(item, "list", $$v)
                },
                expression: "item.list",
              },
            },
            _vm._l(item.list, function (element) {
              return _c(
                "div",
                { key: element.id, staticClass: "item drag-item" },
                [
                  _c("span", [
                    _vm._v(
                      "\n          " + _vm._s(element.name) + "\n        "
                    ),
                  ]),
                  _c("div", { staticClass: "coner-size" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cardSizeMap[element.size.model]) +
                        "\n        "
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-edit-left" }, [
    _c(
      "div",
      { staticClass: "tabs-line" },
      _vm._l(_vm.tabList, function (item, index) {
        return _c(
          "span",
          {
            key: index,
            class: ["single-tab", item.active && "single-tab-active"],
            on: {
              click: function ($event) {
                return _vm.tabChangeHandler(item)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "filter-line" },
      [
        _c("el-input", {
          attrs: { placeholder: "请输入", "suffix-icon": "el-icon-search" },
          model: {
            value: _vm.searchKey,
            callback: function ($$v) {
              _vm.searchKey = $$v
            },
            expression: "searchKey",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "left-list" },
      [_c(_vm.activeTab, { tag: "components" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="FAQ-component-customer">
    <img
      class="backgroud-1 img-background"
      src="@/assets/images/FAQ/customer_1.png"
      alt=""
    />
    <img
      class="button-background img-background"
      @click="readSuccess"
      src="@/assets/images/FAQ/home_2.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "FAQ-component-customer",
  methods: {
    readSuccess() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.FAQ-component-customer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #00000070;
  z-index: 99999;
  .img-background {
    position: absolute;
  }
  .backgroud-1 {
    top: 10px;
    left: 10px;
    height: calc(100vh - 20px);
  }
  .button-background {
    left: calc(50% - 63px);
    bottom: 230px;
    cursor: pointer;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "FAQ-component-customer" }, [
    _c("img", {
      staticClass: "backgroud-1 img-background",
      attrs: { src: require("@/assets/images/FAQ/customer_1.png"), alt: "" },
    }),
    _c("img", {
      staticClass: "button-background img-background",
      attrs: { src: require("@/assets/images/FAQ/home_2.png"), alt: "" },
      on: { click: _vm.readSuccess },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
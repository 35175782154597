var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "single-custom-card" }, [
    _c("div", { staticClass: "title-line" }, [
      _vm._v("\n    " + _vm._s(_vm.dataset.cardName) + "\n  "),
    ]),
    _c("div", { staticClass: "card-image" }, [
      _c("img", {
        staticClass: "card-cover",
        attrs: { src: _vm.coverImageRender(_vm.dataset), alt: "" },
      }),
    ]),
    _c("div", { staticClass: "coner-size" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.cardSizeMap[_vm.dataset.cardSize.mode]) + "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="dashboard-custom-list">
    <div v-for="(item, index) in customList" :key="index">
      <div class="label-line">
        {{ item.catelogLabel }}
      </div>
      <VueDraggable
        v-model="item.list"
        animation="150"
        ghostClass="ghost"
        class="container"
        :group="{ name: 'person', pull: 'clone', put: false }"
        filter=".ignore"
        :clone="cloneHandler"
      >
        <div
          v-for="element in item.list"
          :key="element.id"
          class="item drag-item"
        >
          <span>
            {{ element.name }}
          </span>
          <div class="coner-size">
            {{ cardSizeMap[element.size.model] }}
          </div>
        </div>
      </VueDraggable>
    </div>
  </div>
</template>

<script>
import { VueDraggable } from "vue-draggable-plus";
import { GenNonDuplicateID } from "@/utils/utils.js";
export default {
  name: "dashboard-custom-list",
  components: {
    VueDraggable,
  },
  data() {
    return {
      cardSizeMap: {
        1: "小",
        2: "中",
        3: "大",
      },
      customList: [
        {
          catelogId: 1,
          catelogLabel: "分类1",
          list: [
            {
              name: "工单填报/完成数趋势工单填报/完成数趋势工单填报/完成数趋势工单填报/完成数趋势工单填报/完成数趋势工单填报/完成数趋势",
              id: GenNonDuplicateID(),
              size: {
                model: 1,
              },
            },
            {
              name: "工单填报/完成数趋势",
              id: GenNonDuplicateID(),
              size: {
                model: 2,
              },
            },
            {
              name: "工单填报/完成数趋势",
              id: GenNonDuplicateID(),
              size: {
                model: 3,
              },
            },
          ],
        },
        {
          catelogId: 2,
          catelogLabel: "分类2",
          list: [
            {
              name: "工单填报/完成数趋势",
              id: GenNonDuplicateID(),
              size: {
                model: 1,
              },
            },
            {
              name: "工单填报/完成数趋势",
              id: GenNonDuplicateID(),
              size: {
                model: 2,
              },
            },
            {
              name: "工单填报/完成数趋势",
              id: GenNonDuplicateID(),
              size: {
                model: 3,
              },
            },
          ],
        },
        {
          catelogId: 3,
          catelogLabel: "分类3",
          list: [
            {
              name: "Lebron2",
              id: GenNonDuplicateID(),
              size: {
                model: 1,
              },
            },
            {
              name: "Kobe2",
              id: GenNonDuplicateID(),
              size: {
                model: 2,
              },
            },
            {
              name: "Jordan2",
              id: GenNonDuplicateID(),
              size: {
                model: 3,
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    cloneHandler(element) {
      console.log("element", element);
      const nonDuplicateId = GenNonDuplicateID();
      return {
        name: `${element.name}-${nonDuplicateId}`,
        id: `${element.id}-${nonDuplicateId}`,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-custom-list {
  .label-line {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    color: #95a2b9;
    border-bottom: 1px solid #eff1f3;
    margin: 10px 0;
  }
  .item {
    height: 108px;
    width: 230px;
    margin-bottom: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid #fafafa;
    position: relative;
    .coner-size {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      font-size: 10px;
      color: #ffffff;
      background: #336cff;
      border-radius: 0 10px 0 10px;
    }
  }
}
</style>
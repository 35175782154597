<template>
  <div class="single-custom-card">
    <div class="title-line">
      {{ dataset.cardName }}
    </div>
    <div class="card-image">
      <img class="card-cover" :src="coverImageRender(dataset)" alt="" />
    </div>
    <div class="coner-size">
      {{ cardSizeMap[dataset.cardSize.mode] }}
    </div>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";

export default {
  name: "single-custom-card",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          size: {
            mode: 1,
          },
        };
      },
    },
  },
  data() {
    return {
      cardSizeMap: {
        1: "小",
        2: "中",
        3: "大",
      },
    };
  },
  methods: {
    coverImageRender({ coverPicture, cardType }) {
      const coverMap = {
        bar: 1,
        pie: 2,
        line: 3,
      };
      if (!coverPicture || [1, 2, 3].includes(coverPicture)) {
        return require(`../../../../../assets/images/bms/card_example_${coverMap[cardType]}.png`);
      } else {
        return `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${coverPicture}`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.single-custom-card {
  height: 108px;
  width: 230px;
  margin-bottom: 10px;
  padding: 10px;
  background: #ffffff;
  // 不能设置border和box-shadow，不然拖拽占位时会有一个小框框
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #fafafa;
  border-radius: 10px;
  position: relative;
  .title-line {
    height: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #252d3d;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .card-image {
    width: 210px;
    height: 60px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .card-image-line {
    background-image: url("../../../../../assets/images/dashboard/card-image-line.png");
  }
  .card-image-bar {
    background-image: url("../../../../../assets/images/dashboard/card-image-bar.png");
  }
  .card-image-pie {
    background-image: url("../../../../../assets/images/dashboard/card-image-pie.png");
  }
  .coner-size {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 10px;
    color: #ffffff;
    background: #336cff;
    border-radius: 0 10px 0 10px;
  }
}
</style>